.container {
  @media (min-width: 600px) {
    width: 400px;
    height: 700px;
    border: 1px solid #16193c;
    box-shadow: 0px 4px 4px 0px rgba(1, 1, 1, 0.5);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;

    @media (max-height: 600px) {
      height: 550px;
      height: 550px;
    }
  }

  .addPhotoDetails {
    width: 100%;
    height: 100%;
    .content {
      color: #e5e7eb;
      font-family: 'Wix Madefor Display';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      padding-top: 6rem;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 3rem;
      margin-bottom: 27px;
      @media (min-width: 600px) {
        font-size: 16px;
      }
    }
    .uploadBar {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 22px;
      margin-bottom: 28px;
      width: 100%;
      height: 140px;
      .avatar {
        width: 140px;
        height: 140px;
      }
      .circleDiv {
        background-image: url(../../../../public/images/add.svg);
        background-repeat: no-repeat;
        background-position: center;
        .circleImg {
          width: 140px;
          height: 140px;
        }
      }
    }
    .buttonContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .photoButton {
      font-size: 18px;
      border-radius: 8px;
      border: 1px solid #fff;
      background: #000;
      @media (min-width: 600px) {
        height: 40px;
        font-size: 16px;
      }
    }
    .photoButton:hover {
      background: #000;
    }
    .errorContainer {
      background: #f24343;
      width: 100%;
      height: 48px;
      border-radius: 4px;
      padding-left: 16px;
      color: #fff;
      display: flex;
      align-items: center;
    }

    .btnWrapper {
      margin-bottom: 40px;
      margin-top: 10px;
      @media (max-width: '576px') {
        margin-bottom: 5px;
      }
    }
  }
}
