// paymentCard

.paymentContainer {
  @media (min-width: 600px) {
    width: 400px;
    height: 700px;
    border: 1px solid #16193c;
    box-shadow: 0px 4px 4px 0px rgba(1, 1, 1, 0.5);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    @media (max-height: 600px) {
      height: 550px;
    }
  }
  .paymentContainerDetails::-webkit-scrollbar {
    display: none;
  }
  .paymentContainerDetails {
    height: 650px;
    @media (min-height: 500px) and (max-height: 590px) {
      height: 500px;
    }
    overflow-y: auto;
    padding-top: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 3rem;
    .cardField {
      position: relative;
      width: 100%;
      justify-content: center;
      border: 1px solid #007bff;
      border-radius: 4px;
      height: 40px;
      font-size: 16px;
      color: #333;
    }
    .footer {
      position: fixed;
      bottom: 0;
      left: 0;
      height: 90px;
      width: 100%;
      background-color: #050624;
      z-index: 10000;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 20px;
      font-weight: 700;

      @media (min-width: 600px) {
        // position: absolute;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }

  .btnWrapper {
    button {
      width: 100%;
    }
  }
}
