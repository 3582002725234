.container {
  @media (min-width: 600px) {
    width: 400px;
    height: 700px;
    border: 1px solid #16193c;
    box-shadow: 0px 4px 4px 0px rgba(1, 1, 1, 0.5);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;

    @media (max-height: 600px) {
      height: 550px;
    }
  }
  .emailPage {
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding-top: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 3rem;
    @media (min-width: 600px) {
      top: 45%;
    }
    .appStoreButton {
      border-radius: 6px;
      background: transparent;
      border: 1px solid #e5e7eb;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
    }
    .descriptionContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;
      .description {
        color: var(--Dark-Light-Text, #e5e7eb);
        font-family: 'Wix Madefor Display';
        display: flex;
        font-size: 14px;
        //   text-align: center;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        margin-bottom: 30px;
        /* 19.6px */
      }
    }
  }
  .content {
    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 38px;
      margin-bottom: 4px;
    }
    h5 {
      color: var(--Dark-Light-Text, #e5e7eb);
      font-family: 'Wix Madefor Display';
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 31px;
      line-height: 140%; /* 25.2px */
    }

    .link {
      color: #fff;
      justify-content: center;
      display: flex;
      font-family: 'Wix Madefor Display';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      span {
        color: #00b3ff;
        cursor: pointer;
        font-family: 'Wix Madefor Display';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
}
