.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  background-color: #050624;
  z-index: 10000;
  display: flex;
  flex-direction: row;

  align-items: center;
  color: white;
  padding-left: 15px;
  padding-right: 5px;
  font-size: 22px;
  font-weight: 700;

  .logo {
    height: 37px;
    width: 37px;
    margin-right: 5px;
    margin-top: 10px;
  }

  .clinicAddress {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 0px;
    padding: 8px;
    border: 1px solid #2a2d56;
    font-size: 12px;
    border-radius: 6px;
    text-transform: capitalize;
    // max-width: 140px;
    max-height: 50px;
  }

  @media (min-width: 600px) {
    position: absolute;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
}
