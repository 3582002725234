.container {
  @media (min-width: 600px) {
    width: 400px;
    height: 700px;
    border: 1px solid #16193c;
    box-shadow: 0px 4px 4px 0px rgba(1, 1, 1, 0.5);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;

    @media (max-height: 600px) {
      height: 550px;
      height: 550px;
    }
  }

  .content {
    position: absolute;
    left: 50%;
    top: 25%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-bottom: 3rem;

    .textContainer {
      color: #e5e7eb;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 44px;
      //   @media (min-width: 600px) {
      //     font-size: 18px;
      //   }
    }

    //   h5 {
    //     color: #e5e7eb;
    //     font-size: 24px;
    //     font-style: normal;
    //     font-weight: 700;
    //     margin-bottom: 15px;
    //     @media (min-width: 600px) {
    //       font-size: 18px;
    //     }
    //   }

    //   label {
    //     font-size: 16px;
    //     font-style: normal;
    //     font-weight: 400;
    //     color: #e5e7eb;
    //   }
  }
}
