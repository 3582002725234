.container {
  @media (min-width: 600px) {
    width: 400px;
    height: 700px;
    border: 1px solid #16193c;
    box-shadow: 0px 4px 4px 0px rgba(1, 1, 1, 0.5);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px 15px 5px 15px;

    @media (max-height: 600px) {
      height: 550px;
      height: 550px;
    }
  }
  padding: 5px 15px 5px 15px;
  .locationContainer {
    margin-top: 70px;
    // text-transform: capitalize;
    color: #e5e7eb;
    // border-radius: 6px;
    // border: 1px solid #e5e7eb;
    // box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
    height: 151px;
    .confirmHeader {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .appointmentTime {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 18px;
      margin-top: -5px;
    }
    .locationDetails {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      border: 2px solid #e5e7eb;
      border-radius: 6px;
      .locationTextContainer {
        // padding-top: 8px;
        padding-left: 8px;
        padding-right: 13px;
        // padding-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        .locationWrapper {
          display: flex;
          flex-direction: column;
          gap: 14px;

          @media (max-width: 700px) {
            gap: 8px;
          }
        }
      }
      .locationText {
        text-transform: capitalize;
      }
      .cityAndState {
        display: flex;
        text-transform: capitalize;
      }
      // .mapImage {
      .mapImageItem {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 3.8px !important;
        border-bottom-right-radius: 3.8px !important;
      }
      // position: relative;
      // top: -2px;
      // left: 2px;
      // }
    }
  }
  .offerDetails {
    margin-top: 65px;
    padding-top: 8px;
    padding-left: 13px;
    padding-right: 13px;
    padding-bottom: 8px;
    color: #e5e7eb;
    border-radius: 6px;
    border: 1px solid #e5e7eb;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);

    .offerDetailsHeader {
      font-size: 18px;
      font-weight: 700;
    }
    .offerText {
      margin-top: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 140%;
      margin-bottom: 6px;
    }
  }
  .offerExpires {
    display: flex;
    justify-content: center;
    gap: 4px;
    margin-top: 12px;
    font-weight: 500;
    color: #e5e7eb;
    font-size: 14px;
    .timer {
      margin-top: 1px;
      min-width: 40px;
      color: #007f7c;
      font-weight: 600;
    }
  }
  .cancellationPolicy {
    color: #e5e7eb;
    min-height: 142px;
    margin-top: 11px;
    border-radius: 6px;
    border: 2px solid #e5e7eb;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
    .policyHeader {
      font-size: 14px;
      font-weight: 700;
      margin-top: 4px;
      padding-left: 8px;
      padding-top: 8px;
      padding-right: 8px;
    }
    .cancellation {
      padding: 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 140%;
    }
  }
}
