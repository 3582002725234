.container {
  @media (min-width: 600px) {
    width: 400px;
    height: 700px;
    border: 1px solid #16193c;
    box-shadow: 0px 4px 4px 0px rgba(1, 1, 1, 0.5);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;

    @media (max-height: 600px) {
      height: 550px;
    }
  }
  .header {
    display: flex;
    justify-content: flex-end;
    font-size: 22px;
    font-weight: 700;
    color: white;

    .logo {
      height: 37px;
      width: 37px;
      margin-right: 5px;
      margin-top: 10px;
    }
  }
  .circularProgress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .userDetails {
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 3rem;

    .appStoreButton {
      border-radius: 6px;
      background: transparent;
      border: 1px solid #e5e7eb;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
      font-size: 18px;
      @media (min-width: 600px) {
        font-size: 16px;
      }
    }
    span {
      color: var(--Dark-Light-Text, #e5e7eb);
      font-family: 'Wix Madefor Display';
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      margin-bottom: 30px;
    }
    h5 {
      color: #e5e7eb;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      @media (min-width: 600px) {
        font-size: 18px;
      }
    }
    .bodyContainer {
      // position: absolute;
      // top: 48%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      width: 100%;
      //   .textContainer {
      //     margin-bottom: 3.2rem;
      //     span {
      //       color: #e5e7eb;
      //       font-size: 18px;
      //       font-style: normal;
      //     }
      //   }

      .downloadTextWrapper {
        margin-bottom: 15px;
      }
      .downloadText {
        color: #e5e7eb;
        font-weight: 400;
        line-height: 140%;
      }

      h5 {
        color: #e5e7eb;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        @media (min-width: 600px) {
          font-size: 18px;
        }
      }

      button {
        width: 100%;
        height: 42px;
      }
    }
  }
}

// .circularProgress {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }
