.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 90px;
  width: 100%;
  background-color: #050624;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 20px;
  font-weight: 700;

  // .button {
  //   background-color: #007f7c;
  //   color: white;
  //   border-radius: 6px;
  //   height: 40px;
  //   width: 100%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   font-size: 18px;
  //   font-weight: 700;
  //   @media (min-width: 600px) {
  //     font-size: 16px;
  //   }
  // }

  .trackerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    margin-bottom: 12px;
    margin-top: 5px;
    @media (max-width: 512px) {
      margin-top: 10px;
    }
    div {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
    .activeStep {
      background-color: #007f7c;
    }
    .inactiveStep {
      background-color: #9ea5b6;
    }
  }

  .disableTracker {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    margin-bottom: 12px;
    margin-top: 5px;
    @media (max-width: 512px) {
      margin-top: 10px;
    }
    div {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
    .activeStep {
      background-color: #050624;
    }
    .inactiveStep {
      background-color: #050624;
    }
  }

  @media (min-width: 600px) {
    position: absolute;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}
