.container {
  @media (min-width: 600px) {
    width: 400px;
    height: 700px;
    border: 1px solid #16193c;
    box-shadow: 0px 4px 4px 0px rgba(1, 1, 1, 0.5);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;

    @media (max-height: 600px) {
      height: 550px;
    }

    @media (min-height: 600px) and (max-height: 699px) {
      height: 600px;
    }
  }

  .videoContainer {
    width: 100%;
    height: 100%;
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 80px;
    width: 100%;
    background-color: transparent;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 20px;
    font-weight: 700;

    @media (min-width: 600px) {
      position: absolute;
      bottom: 60px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }

  .circularProgress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
