@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@700&display=swap');
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  user-select: none;
}
html,
body {
  font-family: 'Wix Madefor Display';
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none;
  background-color: #050624;
}

button {
  background-color: #050624;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
  -webkit-transition-delay: 9999s;
}
