.dateRow {
  max-width: 637px;
  width: 100%;
}
.dayContainer {
  // margin-left: 0.3rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 46px !important;
  // width: 30px;
  height: 46px;
  // padding-top: 0.3rem;
  padding-top: 0.2rem;
  @media (min-width: 600px) {
    width: 20px;
    height: 20px;
    margin-left: 0.5rem;
    padding-top: 0.26rem;
  }
  text-align: center;
  font-style: normal;
  cursor: pointer;
  color: #f5f5f5;
  @media (max-width: 374px) {
    height: 40px;
    width: 40px;
  }
}
.selectedDay {
  width: 39px !important;
  height: 39px !important;
  background-color: #f5f5f5;
  color: #050624;
  border-radius: 50%;

  // @media (min-width: 600px) {
  //   width: 35px;
  //   height: 35px;
  // }
  @media (max-width: 600px) {
    width: 42px !important;
    height: 44px !important;
  }
}
.disabledDay {
  color: #565e7f;
}
.date {
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  @media (min-width: 600px) {
    font-size: 12px;
  }
  @media (max-width: 300px) {
    font-size: 10px;
  }
}
.day {
  font-size: 10px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
  font-weight: 400;
  line-height: 140%;
}

// .nextArrow {
//   background: red;
//   height: 30px;
//   width: 30px;
//   // marginLeft: '0.6rem',
//   padding-bottom: 0.3rem;
// }

// .prevArrow {
//   background-color: red;
//   height: 30px;
//   width: 30px;
//   padding-bottom: 0.3rem;
// }
