.container {
  @media (min-width: 600px) {
    width: 400px;
    height: 700px;
    border: 1px solid #16193c;
    box-shadow: 0px 4px 4px 0px rgba(1, 1, 1, 0.5);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;

    @media (max-height: 600px) {
      height: 550px;
    }
  }

  .verifyOtpContainer {
    position: absolute;
    left: 50%;
    top: 31%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-bottom: 3rem;

    .errorContainer {
      background: #f24343;
      width: 100%;
      height: 48px;
      border-radius: 4px;
      padding-left: 16px;
      color: #fff;
      display: flex;
      align-items: center;
    }

    .codeText {
      color: #e5e7eb;
      font-size: 24px;
      margin-bottom: 24px;
      line-height: 130%;
      font-weight: 400;
      // @media (min-width: 600px) {
      //   font-size: 18px;
      // }
    }
    .boldText {
      color: #e5e7eb;
      font-size: 24px;
      // @media (min-width: 600px) {
      //   font-size: 18px;
      // }
      font-weight: 700;
    }

    .signUpText {
      font-size: 16px;
      color: #007f7c;
      margin-left: 4px;
      cursor: pointer;
      margin-bottom: 50px;
    }
    .signUpText:hover {
      opacity: 0.7;
    }
    .signUpText:active {
      opacity: 0.3;
    }

    .btnWrapper {
      .submitBtn {
        height: 48px;
        font-size: 18px;
        @media (min-width: 600px) {
          font-size: 16px;
        }
      }
    }
    .modalPopUp {
      border-right: 8px;
    }
  }
}
