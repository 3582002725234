.container {
  @media (min-width: 600px) {
    width: 400px;
    height: 700px;
    border: 1px solid #16193c;
    box-shadow: 0px 4px 4px 0px rgba(1, 1, 1, 0.5);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;

    @media (max-height: 600px) {
      height: 550px;
      height: 550px;
    }
  }

  // .content {
  //   position: absolute;
  //   left: 50%;
  //   top: 40%;
  //   transform: translate(-50%, -50%);
  //   width: 100%;
  //   padding-left: 1rem;
  //   padding-right: 1rem;
  //   padding-bottom: 3rem;
  // }

  .header {
    display: flex;
    justify-content: flex-end;
    font-size: 22px;
    font-weight: 700;
    color: white;

    .headerText {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 24px;
      top: 10px;
      @media (max-width: 700px) {
        top: 12px;
      }
    }
    .logo {
      height: 37px;
      width: 37px;
      margin-right: 5px;
      margin-top: 7px;
      @media (max-width: 700px) {
        margin-right: 10px;
        margin-top: 12px;
      }
    }
  }
  .content {
    .circularProgress {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .textContainer {
      color: #e5e7eb;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      margin-top: 28px;
      @media (max-width: 700px) {
        margin-top: 40px;
        padding: 5px 15px 5px 24px;
      }
      padding: 5px 15px 5px 20px;
      .messageText {
        font-weight: 400;
      }
      .offerDetails {
        font-size: 17.9px;
        font-weight: 400;
        margin-top: 20px;
        @media (max-width: 700px) {
          margin-top: 25px;
        }
      }
    }
    .offerExpires {
      position: absolute;
      top: 84.7%;
      @media (min-width: 700px) {
        top: 85%;
      }
      left: 50%;
      display: flex;
      justify-content: center;
      transform: translate(-50%, -50%);
      z-index: 10010;
      gap: 4px;
      margin-top: 12px;
      font-weight: 500;
      color: #e5e7eb;
      font-size: 14px;
      .timer {
        margin-top: 1px;
        min-width: 40px;
        color: #007f7c;
        font-weight: 600;
      }
    }
  }
}
