.container {
  @media (min-width: 600px) {
    width: 400px;
    height: 700px;
    border: 1px solid #16193c;
    box-shadow: 0px 4px 4px 0px rgba(1, 1, 1, 0.5);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;

    @media (max-height: 600px) {
      height: 550px;
      height: 550px;
    }
  }

  .content {
    position: absolute;
    left: 50%;
    top: 29.5%;
    @media (max-width: 700px) {
      top: 30.3%;
    }
    transform: translate(-50%, -50%);
    width: 100%;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-bottom: 3rem;

    .textContainer {
      color: #e5e7eb;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 44px;
      //   @media (max-width: 700px) {
      //    font-size: 24px;
      // }
    }

    .formContainer {
      .phoneNumberConsent {
        color: #e5e7eb;
        font-size: 10px;
        // margin-bottom: 50px;
        margin-left: 2px;
      }
      .codeText {
        color: #e5e7eb;
        font-size: 20px;
        margin-bottom: 50px;
        line-height: 1.6;
        font-weight: 400;
        @media (min-width: 600px) {
          font-size: 18px;
        }
      }
      .boldText {
        color: #e5e7eb;
        font-size: 18px;
        @media (min-width: 600px) {
          font-size: 13px;
        }
        font-weight: 700;
      }

      .input {
        width: 100%;
        height: 54px;
        border: 2px solid #32344b;
        border-radius: 6px;
        background: transparent;
        color: #e5e7eb;
        font-size: 18px;
        @media (min-width: 600px) {
          height: 48px;
        }
      }

      .phoneCtn:focus {
        border: none;
        box-shadow: none;
      }
      .phoneCtn {
        @media (min-width: 600px) {
          margin-top: 6px;
        }
        margin-top: 12px;
        margin-bottom: 12px;
      }

      .countryBtn:focus {
        background-color: transparent;
        border: 2px solid #353536;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-right: none;
      }

      .input:hover {
        border: 2px solid #32344b;
        box-shadow: none;
      }

      .input:focus {
        border: 2px solid #32344b;
        box-shadow: none;
      }

      .countryBtn:focus,
      .countryBtn:active {
        border: none;
      }

      .countryBtn:focus {
        background-color: transparent;
      }

      .dropdown {
        height: 216px;
        @media (min-width: 300px) and (max-width: 400px) {
          width: 362px;
        }
        @media (min-width: 401px) and (max-width: 600px) {
          width: 382px;
        }
        @media (min-width: 600px) {
          width: 370px;
        }
        border-radius: 5px;
        border: 2px solid #32344b;
        background-color: #050624;
        color: red !important;
        // &:hover {
        //   background-color: #050624;
        // }
      }

      // hover

      .dropdown {
        li {
          &:hover {
            background-color: #34364e !important;
          }
        }
      }

      .dropdown {
        li {
          span {
            color: #e5e7eb !important;
          }
        }
      }

      //selected

      .dropdown {
        li {
          &[aria-selected='true'] {
            background-color: #34364e !important ;
          }
        }
      }

      // click active
      // .dropdown {
      //   li {
      //     &:active {
      //       background-color: blue !important;
      //     }
      //   }
      // }

      .dropdown::-webkit-scrollbar {
        display: none;
      }
      .dropdown:hover {
        border: 2px solid #32344b;
        background-color: #050624 !important;
      }
      .dropdown:active {
        border: 2px solid #32344b;
        box-shadow: none;
        background-color: #050624 !important;
      }

      .errorContainer {
        background: #f24343;
        width: 100%;
        height: 48px;
        border-radius: 4px;
        padding-left: 16px;
        color: #fff;
        display: flex;
        align-items: center;
      }

      h5 {
        color: #e5e7eb;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 15px;
        @media (min-width: 600px) {
          font-size: 18px;
        }
      }

      label {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: #e5e7eb;
      }

      .nav {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 30px;
        .signUpText {
          font-size: 16px;
          @media (min-width: 600px) {
            font-size: 14px;
          }
          color: #e5e7eb;
          margin-left: 4px;
          cursor: pointer;
          margin-bottom: 50px;
        }
        .signUpText:hover {
          opacity: 0.7;
        }
        .signUpText:active {
          opacity: 0.3;
        }
        .wrongNumber {
          font-size: 18px;
          @media (min-width: 600px) {
            font-size: 14px;
          }
          cursor: pointer;
          color: #e5e7eb;
          font-weight: 700;
          margin-top: 5px;
        }
        .wrongNumber:hover {
          opacity: 0.7;
        }
        .wrongNumber:active {
          opacity: 0.3;
        }
        // display: flex;
        // justify-content: center;
        // text-align: center;
        // @media (max-width: '576px') {
        //   display: flex;
        //   justify-content: center;
        //   text-align: center;
        // }
        h6 {
          color: #e5e7eb;
          font-size: 16px;
          @media (min-width: 600px) {
            font-size: 13px;
          }
          font-style: normal;
          font-weight: 700;
          margin-bottom: 15px;
        }
      }
      .buttonContainer {
        .scheduleText {
          color: #e5e7eb;
          font-size: 14px;
          margin-bottom: 12px;
          // margin-left: 2px;
          line-height: 13px;
          @media (min-width: 600px) {
            font-size: 14px;
          }
        }
        position: absolute;
        left: 50%;
        top: 180%;
        width: 92%;
        transform: translate(-50%, -50%);
        @media (min-width: 600px) {
          margin-top: 24px;
          top: 190%;
        }
      }
    }
  }
}
