.container {
  @media (min-width: 600px) {
    width: 400px;
    height: 700px;
    border: 1px solid #16193c;
    box-shadow: 0px 4px 4px 0px rgba(1, 1, 1, 0.5);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px 15px 5px 15px;

    @media (max-height: 600px) {
      height: 550px;
      height: 550px;
    }
  }
  padding: 5px 15px 5px 15px;

  .header {
    display: flex;
    justify-content: flex-end;
    font-size: 22px;
    font-weight: 700;
    color: white;

    .headerText {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 18px;
      top: 20px;
      // @media (max-width: 700px) {
      //   top: 12px;
      // }
    }
    .logo {
      height: 37px;
      width: 37px;
      // margin-right: 15px;
      margin-right: -5px;
      margin-top: 7px;
      @media (max-width: 700px) {
        // margin-right: 10px;
        // margin-top: 12px;
      }
    }
  }
  .locationWrapper {
    margin-top: 72px;
    height: 440px;
    width: 100%;
    padding-bottom: 5rem;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    @media (min-height: 450px) and (max-height: 599px) {
      height: 280px;
      overflow-y: auto;
    }
    @media (min-height: 601px) and (max-height: 699px) {
      height: 380px;
      overflow-y: auto;
    }
    .locationContainer {
      margin-bottom: 13px;
      text-transform: capitalize;
      color: #e5e7eb;
      border-radius: 6px;
      border: 2px solid #e5e7eb;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
      height: 99px;

      .locationDetails {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        .locationTextContainer {
          padding-top: 4px;
          padding-left: 8px;
          padding-right: 13px;
          padding-bottom: 10px;
          height: 99px;
          display: flex;
          align-items: center;
          justify-content: center;
          .locationTextWrapper {
            display: flex;
            flex-direction: column;
            gap: 20px;
          }

          // @media (max-width: 700px) {
          //   padding-top: 0px;
          // }
        }
        .locationName {
          font-weight: 700;
        }
        .locationText {
          font-weight: 400;
        }
        .cityAndState {
          display: flex;
          text-transform: capitalize;
          font-weight: 400;
        }

        .mapImageItem {
          height: 95.9px;
          width: 126px;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-top-right-radius: 4.2px !important;
          border-bottom-right-radius: 4.2px !important;

          @media (max-width: 700px) {
            height: 95.2px;
            width: 126px;
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
          }
        }
        // position: relative;
        // top: -1px;
        // left: 1px;
      }
    }
  }
  .locationWrapper::-webkit-scrollbar {
    display: none;
  }

  .circularProgress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
